import React from "react";
import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Layout } from "../components/Layout";

export const Error = () => {
  return (
    <Layout sx={{m: 6}}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ textAlign: "left", display: "block", fontWeight: "bold" }}
      >
        404 - stránka nenalezena
      </Typography>
      <Button
        variant="outlined"
        color="error"
        component={Link}
        to="/"
        sx={{ textAlign: "left", marginTop: "20px" }}
      >
        Přejít na hlavní stránku
      </Button>
    </Layout>
  );
};
