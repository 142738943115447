import React from "react";
import { Layout } from "../components/Layout";
import { Box, List, ListItem, ListItemButton, Typography } from "@mui/material";
import { StyledAccordion } from "../components/Accordion/StyledAccordion";
import { StyledAccordionSummary } from "../components/Accordion/StyledAccordionSummary";
import { StyledAccordionDetails } from "../components/Accordion/StyledAccordionDetails";

export const Help = () => {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <>
      <Layout sx={{m: 6}}>
        <div>
          <Typography variant="h4" gutterBottom>
            Jak používat tuto mapu
          </Typography>
          <Box>Pomocí tohoto nástroje můžete:</Box>
          <List sx={{ listStyleType: "disc" }}>
            <ListItem>
              <ListItemButton sx={{ display: "list-item" }}>
                zobrazit, kde je kulturní infrastruktura
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton sx={{ display: "list-item" }}>
                podívejte se na to spolu s údaji o dopravě, plánování, publiku a
                demografických údajích
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton sx={{ display: "list-item" }}>
                stahujte data pro konkrétní geografické oblasti pomocí nástrojů
                na pravé straně
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton sx={{ display: "list-item" }}>
                navrhnout chybějící kulturní infrastrukturu pomocí nástrojů na
                pravé straně
              </ListItemButton>
            </ListItem>
          </List>

          <Typography variant="h4" gutterBottom>
            FAQ
          </Typography>
          <StyledAccordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <StyledAccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography>Collapsible Group Item #1</Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </Typography>
            </StyledAccordionDetails>
          </StyledAccordion>
          <StyledAccordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <StyledAccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Typography>Collapsible Group Item #2</Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </Typography>
            </StyledAccordionDetails>
          </StyledAccordion>
          <StyledAccordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <StyledAccordionSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography>Collapsible Group Item #3</Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </Typography>
            </StyledAccordionDetails>
          </StyledAccordion>
        </div>
      </Layout>
    </>
  );
};
