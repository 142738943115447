import { useQuery } from 'react-query';
import React, { useEffect } from 'react';

export const context = React.createContext({});

export function ConfigProvider(props) {
    const [config, setConfig] = React.useState({});

    const { isPending, error, data } = useQuery({
        queryKey: ['repoData'],
        queryFn: () => fetch('/config.json').then((res) => res.json()),
    });

    useEffect(() => {
        if (data) {
            setConfig(data);
        }
        if (error) {
            console.error('Error while fetching config:', error);
        }
    }, [data, error]);

    return (
        <context.Provider
            value={{
                config,
            }}
        >
            {config.gateway_url && props.children}
        </context.Provider>
    );
}
