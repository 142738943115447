import React from 'react';
import kreativni from '../assets/kreativni.png';
import Mapk from './Images/mapk';

import { Box } from '@mui/material';
export const Logo = () => {
  return (
    <Box className="logo-section" sx={{ dispay: 'flex' }}>
      <a className="logo-link" href="https://www.prahakulturni.eu/">
        <Mapk />
      </a>
      <Box sx={{m: 2}} />      
      <a className="logo-link" href="https://www.kreativnipraha.eu/cs">
        <img
          src={kreativni}
          alt="logo"
        />
      </a>
    </Box>
  );
};
