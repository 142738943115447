import React from "react";
import Box from '@mui/material/Box';

export const ShadowBox = ({ children, props }) => {
  return (
    <Box {...props} display={'inline-block'} boxShadow='0px 6px 24px 0px #0000000D'>
        {children}
    </Box>
  );
};
