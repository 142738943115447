import React from "react";
import { Box, Grid, Typography } from "@mui/material";

export const ItemBox = ({ href, title, img }) => {
    return (
        <Grid item xl={3}  md={4} sm={6} xs={12}>
            <Box sx={{ position: 'relative' }}>
                <a href={href} className="no-styles">
                    <Box sx={{ width: '100%', height: '100%', display: 'flex', position: 'absolute', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography variant="h3" color='white' sx={{ fontWeight: 'bold' }}>
                            {title}
                        </Typography>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        <img src={img} className='box-image' />
                    </Box>
                </a>
            </Box>
        </Grid>
    );
};
