import './App.css';
import React from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Dates } from './pages/Dates';
import { Help } from './pages/Help';
import { About } from './pages/About';
import { Home } from './pages/Home';
import { Error } from './pages/Error';
import { Privace } from './pages/Privace';
import { Cookies } from './pages/Cookies';
import { Accessibility } from './pages/Accessibility';
import { Map } from './pages/Map';
import { Contact } from './pages/Contact';
import { Faq } from './pages/Faq';
import { blue, red } from '@mui/material/colors';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { QueryContext } from './utils/QueryContext';
import { ConfigProvider } from './utils/ConfigContext';
import './assets/Theinhardt-Regular.woff';

export const COLOR_PRIMARY = '#6D8CB8';
export const COLOR_SECONDARY = '#9f8b28';

const theme = createTheme({
  palette: {
    primary: {
      main: COLOR_PRIMARY,
    },
    secondary: {
       main: COLOR_SECONDARY
    }
  },
  typography: {
    fontFamily: ['Theinhardt'],
    h1: {
      fontSize: 64,
    },
    button: {
      textTransform: 'uppercase',
      fontWeight: 'bold'
    }
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <QueryContext>
        <ConfigProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/o-projektu" element={<About />} />
              <Route path="/data-a-metodika" element={<Dates />} />
              <Route path="/navod" element={<Help />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/kontakt" element={<Contact />} />
              <Route path="/mapa" element={<Map />} />
              <Route path="/ochrana-osobnich-udaju" element={<Privace />} />
              <Route path="/prohlaseni-pristupnosti" element={<Accessibility />} />
              <Route path="/cookie-prohlaseni" element={<Cookies />} />
              <Route path="*" element={<Error />} />
            </Routes>
          </BrowserRouter>
        </ConfigProvider>
      </QueryContext>
    </ThemeProvider>
  );
}

export default App;
