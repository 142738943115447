import React from "react";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Layout } from "../components/Layout";
import { Box, Typography } from "@mui/material";
import { useQuery } from 'react-query';
import { CircularProgress } from "@mui/material";
import { useConfig } from "../hooks/useConfig";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const DatasetFrequency = {
  "YEARLY": "Ročně",
  "MONTHLY": "Měsíčně",
  "DAILY": "Denně",
  "WEEKLY": "Týdně",
}

const DatasetStatus = {
  "NEVER_STARTED": "Zatím neimportováno",
  "IMPORTING": "Importuje se",
  "ERROR": "Chyba",
  "SUCCESS": "Importováno"
}

const DatasetImportType = {
  "UPLOAD": "Ruční nahrávání",
  "API": "Nahrávání přes API"
}

export function Dates() {
  const config = useConfig();

  const { isLoading, error, data } = useQuery('data', () =>
    fetch(config.gateway_url).then(res =>
      res.json()
    )
  );

  if (isLoading) return (
    <Layout sx={{m: 6}}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    </Layout>
  );

  if (error || data.error_message) return (
    <Layout sx={{m: 6}}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <p>Došlo k chybě: {error?.message ?? data.error_message}</p>
      </Box>
    </Layout>
  );

  return (
    <>
      <Layout sx={{m: 6}}>
        <Typography variant="h4" gutterBottom>
          Data a metodika
        </Typography>
        <Typography sx={{ mb: 2 }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum
          dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada
          lacus ex, sit amet blandit leo lobortis eget.
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Název</StyledTableCell>
                <StyledTableCell align="left">Popis</StyledTableCell>
                <StyledTableCell align="left">Status</StyledTableCell>
                <StyledTableCell align="left">Frekvence</StyledTableCell>
                <StyledTableCell align="left">Typ importu</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.items.map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell component="td" scope="row">{row.name}</StyledTableCell>
                  <StyledTableCell align="left">{row.description}</StyledTableCell>
                  <StyledTableCell align="left">{DatasetStatus[row.status] ?? row.status}</StyledTableCell>
                  <StyledTableCell align="left">{DatasetFrequency[row.frequency] ?? row.frequency}</StyledTableCell>
                  <StyledTableCell align="left">{DatasetImportType[row.import_type] ?? row.import_type}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Layout>
    </>
  );
}
