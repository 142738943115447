import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Logo } from "./Logo";
import { Link } from "react-router-dom";
import { Stack } from "@mui/material";
import { navItems } from "../pages/constants";
import { COLOR_SECONDARY } from "../App";

const drawerWidth = 270;

function Header(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.url} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <Link to={`/${item.url}`} style={{ textDecoration: "none" }}>
                <ListItemText primary={item.title} />
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const activeItem = navItems.find(it => it.url && document.location.href.endsWith(it.url)) ?? navItems[0];

  return (
    <>
      <AppBar
        component="nav"
        sx={{
          background: "#ff3796",
          padding: "16px 32px",
          color: "rgb(82, 82, 82)",
          textTransform: "uppercase",
          boxShadow: "none",
        }}
      >
        <Toolbar sx={{
          flexWrap: 'wrap',
        }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: {  lg: "none" } }}
          >
            <MenuIcon />
            <Box sx={{m:1}} />
            <Logo />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none",  lg: "block" } }}
          >
            <Logo />
          </Typography>

          <Stack direction={"row"} sx={{ display: {xs: "none",  lg: "flex" } }}>
            {navItems.map((item) => {
              return (
                <ListItem
                  key={item.url}
                  disablePadding
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <ListItemButton
                    >
                    <Link
                      className={"header-links" + ((item.url === activeItem.url) ? ' active' : '')}
                      to={`/${item.url}`}
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      <ListItemText
                        primary={item.title}
                        primaryTypographyProps={{ fontWeight: 'bold'}}
                        sx={[
                          { p: 1, color: 'black', whiteSpace: "nowrap" },
                          item.title === "Mapa" && {
                            backgroundColor: "black",
                            color: COLOR_SECONDARY,
                            display: "flex",
                            flexDirection: "row",
                          },
                        ]}
                      />
                    </Link>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </Stack>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </>
  );
}

Header.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Header;
