import React from "react";

import Header from "./Header";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Box, CssBaseline, Toolbar } from "@mui/material";
import { Footer } from "./Footer";
import krep from "../assets/krep.png";
import city from "../assets/city.png";
import klub from "../assets/klub.png";
import praha from "../assets/praha-black.jpg";
import { ExitToApp } from "@mui/icons-material";

export const Layout = ({ children, ...params }) => {
  return (
    <Box {...params}>
      <Box sx={{display: "flex" }}>
        <CssBaseline />
        <Header />
        <Box component="main" sx= {{width: "100%"}}>
          <Toolbar />
          {children}
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "200px",
          borderTop: "1px solid rgb(222 222 222)",
          padding: "30px 56px",
          marginTop: "0",
          marginBottom: "30px",
          display: "flex",
          flexDirection: "row",
          gap: "50px",
        }}
        rowHeight={164}
      >
        {itemData.map((item) => {
          return (
            <Box>
              <img
                src={item.img}
                alt={item.title}
                loading="lazy"
                key={item.img}
                style={{
                  width: "120px",
                  height: "120px",
                  objectFit: "contain",
                  filter: "grayscale(100%)",
                }}
              />
              <ExitToApp sx={{position: 'absolute', marginLeft: '5px' }} />
            </Box>
          );
        })}
      </Box>
      <Footer />
    </Box>
  );
};

const itemData = [
  {
    img: krep,
    title: "Kreativní",
  },
  {
    img: city,
    title: "Kreativní",
  },
  {
    img: klub,
    title: "Kreativní",
  },
  {
    img: praha,
    title: "Kreativní",
  },
];
