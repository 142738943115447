import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { Layout } from "../components/Layout";
import praha from "../assets/praha.png";
import { Link } from "react-router-dom";

export const About = () => {
  return (
    <>
      <Layout sx={{m: 6}}>
        <Typography variant="h4" gutterBottom>
          O projektu
        </Typography>
        <p>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Vivamus ac
          leo pretium faucibus. Itaque earum rerum hic tenetur a sapiente
          delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut
          perferendis doloribus asperiores repellat. Pellentesque pretium lectus
          id turpis. Pellentesque arcu. Class aptent taciti sociosqu ad litora
          torquent per conubia nostra, per inceptos hymenaeos. Aliquam erat
          volutpat. Etiam dui sem, fermentum vitae, sagittis id, malesuada in,
          quam. Pellentesque pretium lectus id turpis. Nemo enim ipsam
          voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia
          consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
          Nunc tincidunt ante vitae massa. Aenean placerat. Cum sociis natoque
          penatibus et magnis dis parturient montes, nascetur ridiculus mus.
          Donec quis nibh at felis congue commodo. Aenean id metus id velit
          ullamcorper pulvinar. Donec quis nibh at felis congue commodo. Etiam
          quis quam. Cum sociis natoque penatibus et magnis dis parturient
          montes, nascetur ridiculus mus. Excepteur sint occaecat cupidatat non
          proident, sunt in culpa qui officia deserunt mollit anim id est
          laborum. Fusce suscipit libero eget elit. Mauris metus. Aliquam erat
          volutpat. Pellentesque arcu. Nullam at arcu a est sollicitudin
          euismod. Curabitur bibendum justo non orci. Nulla non lectus sed nisl
          molestie malesuada. Integer malesuada. Duis bibendum, lectus ut
          viverra rhoncus, dolor nunc faucibus libero, eget facilisis enim ipsum
          id lacus. Aliquam erat volutpat. Pellentesque sapien. Nulla est.
        </p>
        <img
          src={praha}
          className="Krep-logo"
          alt="logo"
          width="500px"
          sx={{
            margin: "30px auto",
            marginBottom: "20px",
            display: "block",
          }}
        />
        <Stack spacing={2} direction="row" sx={{ marginBottom: "40px" }}>
          <Button
            sx={{
              background: "#ffffff",
              minHeight: "7.75rem",
              padding: "24px",
              color: "black",
              display: "flex",
              alignItems: "flex-end",
              "&:hover": {
                backgroundColor: "#333",
                boxShadow:
                  "0 0 #0000,0 0 #0000,0px 10px 15px -3px rgba(0,51,102,.1),0px 4px 6px -4px rgba(0,51,102,.1)",
              },
            }}
            component={Link}
            to="https://mapk.stdio.cz/viewer/map"
          >
            Mapa kulturní infrastruktury
          </Button>
          <Button
            sx={{
              background: "#ffffff",
              minHeight: "7.75rem",
              padding: "24px",
              color: "black",
              display: "flex",
              alignItems: "flex-end",
              "&:hover": {
                backgroundColor: "#ffffff",
                boxShadow:
                  "0 0 #0000,0 0 #0000,0px 10px 15px -3px rgba(0,51,102,.1),0px 4px 6px -4px rgba(0,51,102,.1)",
              },
            }}
            component={Link}
            to="/data-a-metodika"
          >
            Prohlédněte si datové sady kulturní infrastruktury pro rok 2023
          </Button>
        </Stack>
        <p>
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
          nisi ut aliquip ex ea commodo consequat. Proin mattis lacinia justo.
          Curabitur vitae diam non enim vestibulum interdum. Morbi scelerisque
          luctus velit. Aenean fermentum risus id tortor. Duis aute irure dolor
          in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Etiam bibendum elit eget erat. Vestibulum fermentum tortor
          id mi. Etiam sapien elit, consequat eget, tristique non, venenatis
          quis, ante. Donec vitae arcu. Vivamus ac leo pretium faucibus. Lorem
          ipsum dolor sit amet, consectetuer adipiscing elit. Morbi imperdiet,
          mauris ac auctor dictum, nisl ligula egestas nulla, et sollicitudin
          sem purus in lacus. Mauris dolor felis, sagittis at, luctus sed,
          aliquam non, tellus. Mauris elementum mauris vitae tortor. Curabitur
          ligula sapien, pulvinar a vestibulum quis, facilisis vel sapien.
          Integer in sapien. Integer rutrum, orci vestibulum ullamcorper
          ultricies, lacus quam ultricies odio, vitae placerat pede sem sit amet
          enim. Integer in sapien. Sed ut perspiciatis unde omnis iste natus
          error sit voluptatem accusantium doloremque laudantium, totam rem
          aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
          architecto beatae vitae dicta sunt explicabo. Morbi scelerisque luctus
          velit. Nullam dapibus fermentum ipsum. In sem justo, commodo ut,
          suscipit at, pharetra vitae, orci. Pellentesque habitant morbi
          tristique senectus et netus et malesuada fames ac turpis egestas.
          Maecenas fermentum, sem in pharetra pellentesque, velit turpis
          volutpat ante, in pharetra metus odio a lectus. Integer lacinia. Duis
          sapien nunc, commodo et, interdum suscipit, sollicitudin et, dolor. In
          laoreet, magna id viverra tincidunt, sem odio bibendum justo, vel
          imperdiet sapien wisi sed libero. Vivamus ac leo pretium faucibus.
          Donec iaculis gravida nulla. Excepteur sint occaecat cupidatat non
          proident, sunt in culpa qui officia deserunt mollit anim id est
          laborum. Sed vel lectus. Donec odio tempus molestie, porttitor ut,
          iaculis quis, sem. Etiam ligula pede, sagittis quis, interdum
          ultricies, scelerisque eu. Integer pellentesque quam vel velit. Aenean
          id metus id velit ullamcorper pulvinar. Nullam eget nisl. Phasellus
          rhoncus. Integer malesuada. Aenean id metus id velit ullamcorper
          pulvinar. Nam quis nulla. Etiam dictum tincidunt diam. Lorem ipsum
          dolor sit amet, consectetuer adipiscing elit. Sed ut perspiciatis unde
          omnis iste natus error sit voluptatem accusantium doloremque
          laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore
          veritatis et quasi architecto beatae vitae dicta sunt explicabo.
          Praesent vitae arcu tempor neque lacinia pretium. Etiam bibendum elit
          eget erat. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum. Itaque earum
          rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus
          maiores alias consequatur aut perferendis doloribus asperiores
          repellat. Vivamus luctus egestas leo. Nulla non lectus sed nisl
          molestie malesuada. Vivamus luctus egestas leo.
        </p>
      </Layout>
    </>
  );
};
