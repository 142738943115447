export const navItems = [
  { url: '', title: 'Úvod' },
  {
    url: 'o-projektu',
    title: 'O projektu',
  },
  { url: 'data-a-metodika', title: 'Data a metodika' },
  { url: 'navod', title: 'Návod' },
  { url: 'faq', title: 'Časté dotazy' },
  { url: 'kontakt', title: 'Kontakt' },
  { url: 'mapa', title: 'Mapa' },
];

export const footerItems = [
  {
    url: 'ochrana-osobnich-udaju',
    title: 'Ochrana osobních údajů',
  },
  {
    url: 'prohlaseni-pristupnosti',
    title: 'Prohlášení o přístupnosti',
  },
  { url: 'cookie-prohlaseni', title: 'Prohlášení o používání cookies' },
];
