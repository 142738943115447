import React from 'react';
import { Box, List, ListItem, ListItemText, Stack } from '@mui/material';
import { footerItems } from '../pages/constants';
import { Link } from 'react-router-dom';
import prag from '../assets/praha.svg';

const footerNavItems = footerItems;

export const Footer = () => {
  return (
    <footer>
      <Stack
        direction="row"
        justifyContent={'space-between'}
        sx={{
          padding: '32px 32px',
          display: 'flex',
          width: '100%',
          margin: '0 auto',
        }}
      >
        <Box>
          <Box className="logo-section" sx={{ dispay: 'flex' }}>
            <a className="logo-link" href="https://www.prahakulturni.eu/">
              <img src={prag} className="prag-logo" alt="logo" height="56px" />
              <Box
                className="logo-name"
                sx={{ textTransform: 'uppercase', textDecoration: 'none' }}
              >
                Odbor kultury a<br></br> cestovního ruchu
              </Box>
            </a>
          </Box>
        </Box>

        <List
          sx={{
            flexDirection: 'row',
            display: 'flex',
          }}
        >
          {footerNavItems.map((item) => (
            <ListItem disablePadding key={item.url}>
              <Link
                to={`/${item.url}`}
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                }}
              >
                <ListItemText primary={item.title} sx={{ p: 1 }} />
              </Link>
            </ListItem>
          ))}
        </List>
      </Stack>
    </footer>
  );
};
