import React from "react";
import { Layout } from "../components/Layout";
import { Typography } from "@mui/material";

export const Cookies = () => {
  return (
    <Layout sx={{m: 6}}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ textAlign: "left", display: "block", fontWeight: "bold" }}
      >
        Prohlášení o používání cookies
      </Typography>
    </Layout>
  );
};
