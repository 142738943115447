import React from 'react';
import { Layout } from '../components/Layout';
import Typography from '@mui/material/Typography';
import map from '../assets/map.png';
import eu from '../assets/eu.png';
import { Button, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import blueFirst from '../assets/blue-first.png';
import blueSecond from '../assets/blue-second.png';
import blueThird from '../assets/blue-third.png';
import { ShadowBox } from '../components/ShadowBox';
import { COLOR_SECONDARY } from '../App';
import imgGuide from '../assets/img_guide.svg';
import imgDatasets from '../assets/img_datasets.svg';
import imgAnalysis from '../assets/img_analysis.svg';
import { ItemBox } from '../components/ItemBox';

export const Home = () => {
  return (
    <>
      <Layout>
        <Box>
          <Box className="container">
            <Grid container spacing={7}>
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography
                    gutterBottom
                    className="heading"
                    sx={{ fontWeight: 'bold' }}
                  >
                    <h1>Mapa kulturní infrastruktury</h1>
                  </Typography>
                  <Typography
                    sx={{
                      padding: '10px 0',
                      fontWeight: 'bold',
                      maxWidth: '556px',
                      paddingBottom: '24px',
                    }}
                  >
                    Představení projektu a proč to děláme. Hlavní město Praha
                    podporuje pražskou kulturu zejména formou přímé podpory 16ti
                    příspěvkových organizací a dotacemi v oblasti kultury a
                    umění nezávislým subjektům.
                  </Typography>
                  <Stack spacing={3} direction="row">
                    <Button
                      component={Link}
                      to="/mapa"
                      className="main-button"
                      size="large"
                      sx={{ color: 'black', borderRadius: 0, background: '#ff3796' }}
                    >
                      Otevřít mapu
                    </Button>
                    <Button
                      component={Link}
                      to="/o-projektu"
                      size="large"
                      sx={{ color: 'white', borderRadius: 0, background: COLOR_SECONDARY }}
                    >
                      Víc o projektu
                    </Button>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <ShadowBox>
                  <img src={map} className="main-map" alt="logo" />
                </ShadowBox>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className="gray-section column">
          <Grid container spacing={4} justifyContent={'center'} >
            <ItemBox href='/navod' title='Návod ->' img={imgGuide} />
            <ItemBox href='/data-a-metodika' title='Datové sady' img={imgDatasets} />
            <ItemBox href='/o-projektu' title='Analýzy ->' img={imgAnalysis} />
          </Grid>
        </Box>
        <Box className="w-bg container">
          <Box spacing={7} sx={{display: 'flex', gap: '40px', flexWrap: 'wrap', alignItems: 'center' }}>
            <ShadowBox>
              <img src={map} className="map" alt="logo" />
            </ShadowBox>
            <Grid container md={5}>
              <Grid item>
                <img src={eu} className="eu-logo" alt="logo" />
              </Grid>
              <Grid item>
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{ fontWeight: 'bold' }}
                >
                  Cílem projektu je představení projektu a proč to děláme
                </Typography>
                <ul className="list-items">
                  <li>
                    Uzávěrka příjmu žádostí je jednou ročně (většinou v červnu)
                  </li>
                  <li>
                    Žádosti o jednoletou dotaci se podávají se na rok
                    následující – tedy v roce 2023 bude uzávěrka pro projekty,
                    které budou probíhat v roce 2024
                  </li>
                  <li>
                    Žádosti o víceletou dotaci se podávají s ročním předstihem –
                    tedy v roce 2023 bude uzávěrka pro projekty, které budou
                    probíhat od r. 2025 dále
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Layout>
    </>
  );
};
