import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="95.368"
      height="35.998"
      version="1.1"
      viewBox="0 0 25.233 9.524"
    >
      <defs>
        <clipPath id="clipPath86" clipPathUnits="userSpaceOnUse">
          <path
            d="M0 0h841.89v595.276H0z"
            transform="translate(-193.617 -491.643)"
          ></path>
        </clipPath>
        <clipPath id="clipPath84" clipPathUnits="userSpaceOnUse">
          <path
            d="M0 0h841.89v595.276H0z"
            transform="translate(-196.467 -500.273)"
          ></path>
        </clipPath>
        <clipPath id="clipPath88" clipPathUnits="userSpaceOnUse">
          <path
            d="M0 0h841.89v595.276H0z"
            transform="translate(-200.185 -485.36)"
          ></path>
        </clipPath>
      </defs>
      <g
        fill="#000000 icc-color(sRGB-IEC61966-2, 0.1, 0, 0, 0)"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        transform="translate(-41.01 -106.362)"
      >
        <path
          strokeWidth="0.353"
          d="M57.21 107.878l1.998 8.009 2.566-.64-1.997-8.01z"
        ></path>
        <path
          d="M0 0a4.15 4.15 0 11-2.009 8.055A4.15 4.15 0 010 0"
          clipPath="url(#clipPath84)"
          transform="matrix(.35278 0 0 -.35278 63.821 109.248)"
        ></path>
        <path
          d="M0 0a4.15 4.15 0 11-2.009 8.055A4.15 4.15 0 010 0"
          clipPath="url(#clipPath86)"
          transform="matrix(.35278 0 0 -.35278 62.816 112.293)"
        ></path>
        <path
          d="M0 0a4.151 4.151 0 11-2.01 8.055A4.151 4.151 0 010 0"
          clipPath="url(#clipPath88)"
          transform="matrix(.35278 0 0 -.35278 65.133 114.509)"
        ></path>
        <path
          strokeWidth="0.353"
          d="M42.168 112.62c0-.625 0-1.348-.008-1.964h.008c.186.67.401 1.43.67 2.294l.595 1.799h1.1l.612-1.753c.272-.815.5-1.654.686-2.34h.008c-.016.608-.041 1.348-.041 1.964v2.129h1.22v-5.085h-1.77l-.67 1.976c-.128.38-.314 1.058-.475 1.703h-.017a19.66 19.66 0 00-.459-1.662l-.678-2.017H41.01v5.085h1.158zm9.055 2.129h1.314l-1.967-5.085h-1.423l-1.967 5.085h1.26l.377-1.042h2.042zm-1.832-2.646c.145-.405.298-.831.426-1.236h.058c.128.405.269.83.413 1.236l.224.62h-1.34zm5.23-1.356c.558 0 .814.223.814.686 0 .476-.272.699-.798.699h-.719v-1.385zm.153 2.468c1.236 0 1.93-.79 1.93-1.782 0-1.166-.653-1.77-1.971-1.77h-2.034v5.086h1.22v-1.534zm0 0"
          ariaLabel="MAP"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
